import React, { Component, useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom/cjs/react-router-dom.min";
import jwt_decode from "jwt-decode";
import CryptoJS from 'crypto-js';

export default class leftSidebar extends Component {

  // logout
  logoutUser() {
    localStorage.removeItem("jwtToken");
    window.location.replace("/");
  }

  render() {
    // Token
    const token = localStorage.getItem("jwtToken");
    const decodedToken = jwt_decode(token);
    const userInfo = decodedToken;
    const CheckStatus = userInfo.status;
    const user_role =  userInfo.role_as;
    const user_id =  userInfo.user_id;


    const secretKey = 'Maxx12#';    
    const encryptUserId = (userId) => {
      const encrypted = CryptoJS.AES.encrypt(userId, secretKey).toString();
      // Replace URL-unsafe characters
      const urlSafeEncrypted = encrypted
        .replace(/\//g, '_')  // Replace '/' with '_'
        .replace(/\+/g, '-')  // Replace '+' with '-'
        .replace(/=/g, '');   // Remove '=' padding
      return urlSafeEncrypted;
    };

    const encryptedUserId = encryptUserId(user_id);
    
    const createAgent = (() => {
      if(user_role === 5){
        return (
             <>
              <li>
                <Link to="/subreseller-index" className="" ariaExpanded="false">
                  <i class="material-icons">grid_view</i>
                  <span class="nav-text"> Create Admin </span>
                </Link>
              </li>
              <li>
                  <Link to={`/down-list/${encryptedUserId}`} className="" aria-expanded="false">
                    <i className="material-icons">credit_card</i>
                    <span className="nav-text"> Downline List</span>
                  </Link>
              </li>
              </>
        );
      } 
    })();



    return (
      <>
        <div className="deznav">
          <div className="deznav-scroll">
            <ul className="metismenu" id="menu">
              <li>
                <Link to="/superadmin" className="" ariaExpanded="false">
                  <i class="material-icons">grid_view</i>
                  <span class="nav-text">Dashboard</span>
                </Link>
              </li>
         
              <li>
                <Link to="/user-balance-report" className="" ariaExpanded="false">
                  <i class="material-icons">credit_card</i>
                  <span class="nav-text">Balance Transfer</span>
                </Link>
              </li>
      
              {/* <li>
                 <Link to="/balance-deposit" className="" ariaExpanded="false">
                       <i class="material-icons">credit_card</i>
                      <span class="nav-text">Balance Deposit </span>
                    </Link>
              </li> */}

            
              <li>
                   <Link to="/balance-from-admin" className="" ariaExpanded="false">
                   <i class="material-icons">credit_card</i>
                     <span class="nav-text"> Deposit Received Report</span>
                    </Link>
              </li>
            
          

              {/* <li>
                 <Link to="/balance-withdraw-history" className="" ariaExpanded="false">
                       <i class="material-icons">credit_card</i>
                      <span class="nav-text">Balance Withdraw</span>
                    </Link>
              </li> */}

{/* 
              <li>
                   <Link to="/add-wallet-list" className="" ariaExpanded="false">
                     <i class="material-icons">credit_card</i>
                     <span class="nav-text"> Add Wallet</span>
                    </Link>
              </li>  */}


              {/* <li>
                <a
                  href="javascript:void(0);"
                  className="has-arrow "
                  ariaExpanded="false"
                >
                  <i className="material-icons">description</i>
                  <span className="nav-text"> Send Money </span>
                </a>

                <ul ariaExpanded="false">
                  <li>
                    <Link to="/user-bal-list" className="" ariaExpanded="false">
                      <span class="nav-text">User Balance Transfer</span>
                    </Link>
                  </li>

                  <li>
                  
                    <Link to="/user-balance-report">
                      <span class="nav-text">Balance Report</span>{" "}
                    </Link>
                  </li>
                </ul>
              </li> */}

        
            

             {/*   <li>
                <a href="javascript:void(0);" className="has-arrow " ariaExpanded="false">
                  <i className="material-icons">description</i>
                  <span className="nav-text">Cashin In  (Sub Reseller) </span>
                </a>
                <ul ariaExpanded="false">
                  <li>
                    <Link to="/withdraw">Cash In Voucher</Link>
                    <Link to="/withdraw">Cash In History</Link>
                  </li>
                </ul>
              </li>   */}
              
             
                {createAgent}
          
           
              <li>
                <a
                  href="javascript:void(0);"
                  className="has-arrow "
                  ariaExpanded="false"
                >
                  <i className="material-icons">description</i>
                  <span className="nav-text">Profile Manage </span>
                </a>

                <ul ariaExpanded="false">
                  <li>
                    <Link to="/profile-manage">Profile Setting</Link>
                  </li>

                  <li>
                    <Link to="/password-manage">Password Manage</Link>
                  </li>

                  <li>
                    <Link to="/tpin-manage">S-Pin Manage</Link>
                  </li>
                  {/* <li>
                    <a href="javascript:void(0);">2-factor Authentication </a>
                  </li> */}
                </ul>
              </li>

              {/* <li>
                <Link to="/chats" className="" ariaExpanded="false">
                  <i class="material-icons">credit_card</i>
                  <span class="nav-text">Chats</span>
                </Link>
              </li>
      
              <li>
                <Link to="/new-chats" className="" ariaExpanded="false">
                  <i class="material-icons">credit_card</i>
                  <span class="nav-text">New Chats</span>
                </Link>
              </li> */}
      
              <li>
                <a href="#" className="" ariaExpanded="false">
                  <button className="btn btn-danger" onClick={this.logoutUser}>
                    Log Out
                  </button>
                </a>
              </li>
              
            </ul>
          </div>
        </div>
      </>
    );
  }
}
